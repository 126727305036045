export function HomeInfo() {
    let items = [
        {
            number: "01",
            text: "<red>O'ZINGIZGA</red> bo'lgan <red>ISHONCHNI</red> yo'qotib qo'ygan bo'lsangiz va hech narsaga qiziqish va xohish bo'lmasa"
        },
        {
            number: "02",
            text: "<red>TURMUSH QURISHDA</red> <br/> qo'rquv va yaqinlik qo'rquvlari sizni qiynasa"
        },
        {
            number: "03",
            text: "<red>TEZ-TEZ TUSHKUNLIKKA TUSHIB</red> qolasiz va hayotingizga ko'ngilsiz hodisalarni ko'payishi"
        },
        {
            number: "04",
            text: "<red>JINSIY HAYOTINGIZNI</red> sifatini o'zgartirmoqchisiz"
        },
        {
            number: "05",
            text: "<red>ORGAZM</red> nimaligini bilmaysiz va ko'zlari yonib turadiganz"
        }
    ]

    return (
        <div className="home-info">
            <div className="home-title">Bu kurs siz uchun agarda:</div>

            <div className="home-info__content">
                {items.map((item, index) => (
                    <div className="home-info__item" key={index}>
                        <div className="home-info__item__number">{item.number}</div>
                        <div className="home-info__item__text"
                             dangerouslySetInnerHTML={{ __html: item.text }}
                         />
                    </div>
                ))}
            </div>
        </div>
    )
}