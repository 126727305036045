import { InstagramIcon } from "../../../img/icons/InstagramIcon";
import { TelegramIcon } from "../../../img/icons/TelegramIcon";
import { YoutubeIcon } from "../../../img/icons/YoutubeIcon";

export function HomeFooter() {

    function openInstagram() {
        let link = "https://www.instagram.com/nadia.abdullakhodjaeva?igsh=cWg5anJ1bG50b3d2"
        window.open(link, "_blank")
    }

    function openYoutube() {
        let link = "https://www.youtube.com/@nadiaabdullakhodjaeva"
        window.open(link, "_blank")
    }

    function openTelegram() {
        let link = "https://t.me/nadia_admini"
        window.open(link, "_blank")
    }

    return (
        <div className="home-footer">

            <div className="home-footer__content">
                <div className="home-footer__content__left">
                    "NADIA’S SCHOOL" MAS'ULIYATI CHEKLANGAN JAMIYAT
                    <br /><br />
                    inn: 311141396<br />
                    x/r: 20208000007020344001<br />
                    МФО: 01036<br />
                    Банк: ТОШКЕНТ Ш., "ИПАК ЙУЛИ" АИТ БАНКИНИНГ САГБОН ФИЛИАЛИ<br />
                </div>

                <div className="home-footer__content__right">
                    <div className="home-footer__link">Публичная оферта</div>
                    <div className="home-footer__link">Публичная оферта</div>
                    <div className="home-footer__link">Политика конфиденциальности</div>

                    <div className="home-footer__social">
                        <div className="home-footer__social__link" onClick={openInstagram}>
                            <InstagramIcon />
                        </div>
                        <div className="home-footer__social__link" onClick={openYoutube}>
                            <YoutubeIcon />
                        </div>
                        <div className="home-footer__social__link" onClick={openTelegram}>
                            <TelegramIcon />
                        </div>
                    </div>
                </div>
            </div>

            <div className="home-footer__copyright">
                © 2024 Nadia’s school. Все права защищены
            </div>
        </div>
    );
}