import { HomeHeader } from "../../components/home/header";
import { HomeInfo } from "../../components/home/info";
import { HomeQuestions } from "../../components/home/questions/HomeQuestions";
import { HomeDeadline } from "../../components/home/deadline";
import { HomeFeedback } from "../../components/home/feedback";
import { HomeFooter } from "../../components/home/footer";

export function HomePage() {
    return (
        <div className="home">
            <HomeHeader />
            <HomeInfo />
            <HomeQuestions />
            <HomeDeadline />
            <HomeFeedback />
            <HomeFooter />
        </div>
    );
}