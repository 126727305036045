export function HomeQuestions() {

    const items = [
        {
            "text": "1. O'zinga ishonch"
        },
        {
            "text": "2. Xaqiqiy baxtga erishish"
        },
        {
            "text": "3. Lazzatli xayotni boshlanishi"
        },
        {
            "text": "4. Ideal ayol bo'lish sirlari"
        },
        {
            "text": "5. Qo'rquv va cheklovlardan xalos bo'lish"
        }
    ];

    return (
        <div className="home-questions">
            <div className="home-questions__gradient"/>

            <div className="home-questions__left">
                <img className="home-questions__left__image" src="/images/home/avatar_2.png"
                     alt="" />

                <div className="home-questions__left__text">
                    Baxtli bo’lish sirlari<br />va xotirjamlik
                </div>
            </div>

            <div className="home-questions__right">
                <div className="home-title">Kurs sizga nima beradi?</div>

                <div className="home-questions__content">
                    {items.map((item, index) => (
                        <div className="home-questions__item" key={index}>
                            {item.text}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}