export function InstagramIcon() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                  stroke="#C73659" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round" />
            <path
                d="M12 15.5C13.933 15.5 15.5 13.933 15.5 12C15.5 10.067 13.933 8.5 12 8.5C10.067 8.5 8.5 10.067 8.5 12C8.5 13.933 10.067 15.5 12 15.5Z"
                stroke="#C73659" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
            <path d="M17.6361 7H17.6477" stroke="#C73659" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" />
        </svg>
    );
}