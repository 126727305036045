export function HomeHeaderSeparator() {
    return (
        <svg width="1440" height="100" viewBox="0 0 1440 100" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_209_190)">
                <g filter="url(#filter0_b_209_190)">
                    <path
                        d="M0 0C0 0 127 29.0107 360 29.0107C593 29.0107 675 29.0107 675 29.0107C687 29.0108 684 70.5931 720 70.5931C756 70.5931 749.5 29.0107 765 29.0107C765 29.0107 855 29.0107 1080 29.0107C1305 29.0107 1440 0 1440 0V648H0V0Z"
                        fill="#FFE6EC" />
                </g>
            </g>
            <defs>
                <filter id="filter0_b_209_190" x="-64" y="-64" width="1568" height="776"
                        filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="32" />
                    <feComposite in2="SourceAlpha" operator="in"
                                 result="effect1_backgroundBlur_209_190" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_209_190"
                             result="shape" />
                </filter>
                <clipPath id="clip0_209_190">
                    <rect width="1440" height="100" fill="white" />
                </clipPath>
            </defs>
        </svg>

    );
}