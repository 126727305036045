export function HomeDeadline() {

    return (
        <div className="home-deadline">
            <div className="home-deadline__gradient"></div>

            <div className="home-title" style={{ lineHeight: '130%' }}>
                19-dekabr kuni kurs<br />sotuvi yopiladi!
            </div>

            <div className="home-deadline__content">
                <div className="home-deadline__content__title">
                    SHOSHILIB QOLING!
                </div>

                <div className="home-deadline__content__description">
                    To'liq info uchun shapka profiliga yozing va + qoldiring
                </div>
            </div>
        </div>
    );
}