import { HomeHeaderSeparator } from "../../../img/home/HomeHeaderSeparator";

export function HomeHeader() {
    return (
        <div className="home-header">
            <div className="home-header__gradient"></div>

            <div className="home-header__topbar">
                <img src="/images/home/logo.png" alt="" />

                <div className="home-header__topbar__content">
                    <div className="home-header__topbar__date">20-dekabr</div>
                    <div className="home-header__topbar__start">START</div>
                </div>
            </div>

            <div className="home-header__elements">
                <img className="home-header__elements__top" src="/images/home/top-element.png"
                     alt="" />
                <img className="home-header__elements__bottom" src="/images/home/bottom-element.png"
                     alt="" />
            </div>

            <div className="home-header__avatar">
                <img className="home-header__avatar__bg"
                     src="/images/home/avatar-bg.png"
                     alt="" />

                <div className="home-header__avatar__itself">
                    <img
                        src="/images/home/avatar.png"
                        alt="" />

                    <div className="home-header__talk">
                        Bu yo’lda sizga bardavom <br />
                        bo’lishda yordam beraman!
                    </div>

                </div>
            </div>

            <div className="home-header__content">
                <div className="home-header__content__category">Terapevtik kurs</div>
                <div className="home-header__content__title_1">Siz Baxtli</div>
                <div className="home-header__content__title_2">bo’lasiz</div>
                <div className="home-header__content__separator"></div>
                <div className="home-header__content__desc">
                    Haqiqiy baxt topish uchun uzoq vaqt
                    yoqotishingiz mumkin. Lekin haqiqiy baxt kaliti qo’lingizda ekanligini
                    tushunganingizda hayotingiz 180 darajaga o’zgaradi
                </div>

               <div className="home-header__content__button">
    <a href="https://t.me/nadiaa_kursbot?start=start" target="_blank" rel="noopener noreferrer">
        Курсга езилиш
    </a>
</div>       </div>

            <div className="home-header__separator">
                <HomeHeaderSeparator />
            </div>
        </div>
    );
}