import { Swiper, SwiperSlide } from "swiper/react";
import { useEffect, useState } from "react";

export function HomeFeedback() {
    const isMobile = window.screen.width < 1250

    const items = [
        {
            image: "/images/slides/screen_1.png"
        },
        {
            image: "/images/slides/screen_2.png"
        },
        {
            image: "/images/slides/screen_3.png"
        },
        {
            image: "/images/slides/screen_4.png"
        },
        {
            image: "/images/slides/screen_5.png"
        },
        {
            image: "/images/slides/screen_6.png"
        },
        {
            image: "/images/slides/screen_7.png"
        }
    ];

    const [scales, setScales] = useState(Array(items.length).fill(0.7));

    const updateScales = (swiper) => {
        const centerIndex = swiper.realIndex;
        const newScales = items.map((_, index) => {
            const diff = Math.abs(centerIndex - index);
            if (diff === 0) return 1.0; // Center element
            if (diff === 1 || diff === items.length - 1) return 0.7; // Adjacent elements
            return 0.5; // All others
        });
        setScales(newScales);
    };

    useEffect(() => {
        // Initialize scales when the component mounts
        setScales(items.map((_, index) => (index === 0 ? 1.0 : 0.7)));
    }, []);

    return (
        <div className="home-feedback">
            <div className="home-feedback__gradient_1"></div>
            <div className="home-feedback__gradient_2"></div>

            <div className="home-title">Kursdan otzivlar</div>

            <div className="home-feedback__slider">
                <Swiper
                    id={"slider"}
                    spaceBetween={isMobile ? 0 : 20}
                    slidesPerView={isMobile ? 3 : 5}
                    centeredSlides={true}
                    loop={true}
                    autoplay={{ delay: 1000, disableOnInteraction: false }}
                    pagination={{
                        clickable: true
                    }}
                    onSlideChange={updateScales}
                    onSwiper={(swiper) => updateScales(swiper)} // Initialize on load
                    className="mySwiper"
                >
                    {items.map((item, index) => (
                        <SwiperSlide key={index}>
                            <div className="home-feedback__item">
                                <img src={item.image} alt=""
                                     style={{
                                         transform: `scale(${scales[index]})`,
                                         opacity: 1 * scales[index],
                                         transition: "transform 0.3s ease"
                                     }}
                                />
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    );
}